
import { Vue, prop, Options } from "vue-class-component";

class Props {
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  message = prop<string>({
    type: String,
    default: "Copied"
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class SnackbarCopy extends Vue.with(Props) {
  enter() {
    setTimeout(() => {
      this.$emit("update:modelValue", false);
    }, 1000);
  }
}
